import styled, { keyframes } from "styled-components";

export const Pulse = keyframes`
    0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    }   
    50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    }
    100% {
        -webkit-transform: scale(1);
    transform: scale(1);
    }
`;


export const CarIconStyle = styled.svg`
`;
