import React from 'react'

// Assuming the types for these components are already defined
import Building from './Icons/Building'
import Cancel from './Icons/Cancel'
import CarIcon from './Icons/CarIcon'
import Home from './Icons/Home'
import Mall from './Icons/Mall'
import {
  OrderStatusMobileIconWrapper,
  OrderStatusIcons,
} from 'styles/orderStatus'

interface IconProps {
  color: string
  width: number
  height: number
}

interface IconData {
  type: string
  Component: React.FC<IconProps>
  iconCheck: number
  width: number
  height: number
}

const ICON_DATA: IconData[] = [
  {
    type: 'car',
    Component: CarIcon,
    iconCheck: 0,
    width: 40,
    height: 36,
  },
  {
    type: 'building',
    Component: Building,
    iconCheck: 1,
    width: 32,
    height: 32,
  },
  {
    type: 'mall',
    Component: Mall,
    iconCheck: 2,
    width: 32,
    height: 34,
  },
  {
    type: 'home',
    Component: Home,
    iconCheck: 3,
    width: 30,
    height: 34,
  },
]

interface OrderStatusIconWrapperProps {
  color: string
  children: React.ReactNode
}

export const OrderStatusIconWrapper: React.FC<
  OrderStatusIconWrapperProps
> = ({ color, children }) => {
  return (
    <div
      className="relative max-xs:min-w-[70px] max-xs:w-[70px] max-xs:px-3 min-w-[88px] min-h-[48px] w-[88px] h-[48px] flex flex-col justify-end items-center gap-2 px-6 pb-3 border-b-4"
      style={{ borderBottomColor: color }}
    >
      {children}
    </div>
  )
}

interface RenderIconProps extends IconData {
  fulfillment?: { status: string }
  iconColor: number
  primaryColor: string
}

const RenderIcon: React.FC<RenderIconProps> = ({
  Component,
  iconCheck,
  width,
  height,
  fulfillment,
  iconColor,
  primaryColor,
}) => {
  const isCancelled = fulfillment?.status === 'cancelled'
  let iconColorValue = '#D3D3D3'

  if (isCancelled && iconColor === iconCheck) {
    iconColorValue = 'red'
  } else if (iconColor >= iconCheck) {
    iconColorValue = primaryColor
  }

  return (
    <OrderStatusIconWrapper
      className="relative min-w-[88px] min-h-[48px] w-[88px] h-[48px]"
      color={iconColorValue}
    >
      <div className="absolute top-0">
        <Component
          color={iconColorValue}
          width={width}
          height={height}
        />
      </div>
    </OrderStatusIconWrapper>
  )
}

// Assuming fulfillment, iconColor, and primaryColor are coming from props or state
const StatusIcon: React.FC<{
  fulfillment?: { status: string }
  iconColor: number
  primaryColor: string
}> = ({ fulfillment, iconColor, primaryColor }) => {
  return (
    <OrderStatusMobileIconWrapper>
      <OrderStatusIcons>
        {ICON_DATA.map(icon => (
          <RenderIcon
            key={icon.type}
            {...icon}
            fulfillment={fulfillment}
            iconColor={iconColor}
            primaryColor={primaryColor}
          />
        ))}
      </OrderStatusIcons>
    </OrderStatusMobileIconWrapper>
  )
}

export default StatusIcon
