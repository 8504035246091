import React from 'react'

interface TimelineProps {
  items: {
    statusText: string
    statusTime: string
  }[]
}

const Timeline: React.FC<TimelineProps> = ({ items }) => {
  // Helper function to format the date
  const formatDate = (isoString: string) => {
    const date = new Date(isoString)
    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  return (
    <div className="space-y-4">
      {items.map((item, index) => (
        <div
          key={index}
          className="flex items-center justify-between"
        >
          <div className="flex items-center font-medium">
            <div className="h-2 w-2 mb-[2px] bg-gray-300 rounded-full mr-3"></div>
            <span>{item.statusText}</span>
          </div>
          <span className="text-gray-500">
            {formatDate(item.statusTime)}
          </span>
        </div>
      ))}
    </div>
  )
}

export default Timeline
