import { HomeIcon } from "@heroicons/react/24/solid";

const HomeIconMarker = ({}: any) => {
  return (
    <div className="rounded-full bg-black p-[6px]">
   <HomeIcon className="w-4 h-4 text-white" />
  </div>
  );
};

export default HomeIconMarker;