import { CarIconStyle } from "styles/IconStyles";
import { BuildingStorefrontIcon } from '@heroicons/react/24/solid'

const MarkerIconMerchant = ({ width, height, color, pulse }: any) => {
  return (
    <div className="rounded-full bg-black p-[6px]">
      <BuildingStorefrontIcon className="w-4 h-4 text-white" />
    </div>
  );
};

export default MarkerIconMerchant;
