import styled from 'styled-components'

export const Container = styled.div`
  padding: 0;
`

export const Main = styled.main`
  min-height: 100vh;
  padding: 1rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
`
export const Footer = styled.footer`
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
`
export const Head = styled.head``

export const Input = styled.input``
