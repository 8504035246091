import { CarIconStyle } from "styles/IconStyles";

const Mall = ({color, height, width}:any) => {
  return (
    <CarIconStyle
      width={height ? height : "31"}
      height={height ? height : "31"}
      xmlns="http://www.w3.org/2000/svg"
      fill={color ? color : "#1F5EA9"}
    >
     <path d="M18.9877 6.9825C18.9877 5.65973 18.4622 4.39114 17.5269 3.4558C16.5915 2.52047 15.3229 1.995 14.0002 1.995C12.6774 1.995 11.4088 2.52047 10.4735 3.4558C9.53812 4.39114 9.01266 5.65973 9.01266 6.9825V7.98H18.9877V6.9825ZM20.9827 6.9825V7.98H27.9652V27.93C27.9652 28.9882 27.5448 30.0031 26.7965 30.7514C26.0482 31.4996 25.0334 31.92 23.9752 31.92H4.02516C2.96694 31.92 1.95207 31.4996 1.2038 30.7514C0.45553 30.0031 0.0351563 28.9882 0.0351562 27.93V7.98H7.01766V6.9825C7.01766 5.13063 7.75331 3.3546 9.06278 2.04513C10.3723 0.735654 12.1483 0 14.0002 0C15.852 0 17.6281 0.735654 18.9375 2.04513C20.247 3.3546 20.9827 5.13063 20.9827 6.9825ZM19.6939 17.6637C19.8812 17.4764 19.9864 17.2224 19.9864 16.9575C19.9864 16.6926 19.8812 16.4386 19.6939 16.2513C19.5066 16.064 19.2525 15.9587 18.9877 15.9587C18.7228 15.9587 18.4687 16.064 18.2814 16.2513L13.0027 21.532L10.7164 19.2438C10.6236 19.151 10.5135 19.0775 10.3924 19.0273C10.2712 18.9771 10.1413 18.9512 10.0102 18.9512C9.879 18.9512 9.74912 18.9771 9.62795 19.0273C9.50677 19.0775 9.39667 19.151 9.30393 19.2438C9.21118 19.3365 9.13761 19.4466 9.08742 19.5678C9.03723 19.689 9.0114 19.8188 9.0114 19.95C9.0114 20.0812 9.03723 20.211 9.08742 20.3322C9.13761 20.4534 9.21118 20.5635 9.30393 20.6562L12.2964 23.6487C12.3891 23.7416 12.4992 23.8153 12.6203 23.8656C12.7415 23.9159 12.8715 23.9418 13.0027 23.9418C13.1339 23.9418 13.2638 23.9159 13.385 23.8656C13.5062 23.8153 13.6162 23.7416 13.7089 23.6487L19.6939 17.6637Z"/>
    </CarIconStyle>
  );
};

export default Mall;
