/* eslint-disable @next/next/no-img-element */

interface FooterImageProps {
  image: string
  link?: string
}

const FooterImage: React.FC<FooterImageProps> = ({ image, link }) => {
  const handleClick = () => {
    if (link) {
      window.open(link, '_blank')
    }
  }

  return (
    <div className="flex-col content-center w-full">
      {image && (
        <img
          src={image}
          onClick={handleClick}
          alt="Footer Image"
          className="object-contain w-full h-auto cursor-pointer"
        />
      )}
    </div>
  )
}

export default FooterImage
