import { CarIconStyle } from "styles/IconStyles";

const Home = ({color, width, height}:any) => {
  return (
    <CarIconStyle
      width={width ? width : "31"}
      height={height ? height : "31"}
      viewBox="0 0 31 31"
      xmlns="http://www.w3.org/2000/svg"
      fill={color ? color : "#1F5EA9"}
    >
      <path d="M11.6791 30.3529V22.5923C11.6791 22.0498 12.2327 21.4963 12.7862 21.4963H17.2145C17.768 21.4963 18.3216 22.0498 18.3216 22.6034V30.3529C18.3216 30.6465 18.4382 30.9281 18.6458 31.1357C18.8534 31.3433 19.135 31.46 19.4286 31.46H28.2852C28.5788 31.46 28.8604 31.3433 29.068 31.1357C29.2756 30.9281 29.3923 30.6465 29.3923 30.3529V14.8539C29.3925 14.7084 29.3641 14.5643 29.3087 14.4298C29.2532 14.2953 29.1718 14.1731 29.069 14.0701L26.0711 11.0743V3.78316C26.0711 3.48954 25.9544 3.20795 25.7468 3.00034C25.5392 2.79272 25.2576 2.67608 24.964 2.67608H22.7498C22.4562 2.67608 22.1746 2.79272 21.967 3.00034C21.7594 3.20795 21.6428 3.48954 21.6428 3.78316V6.64605L15.7841 0.785205C15.6813 0.682107 15.5591 0.60031 15.4246 0.5445C15.2901 0.488689 15.146 0.459961 15.0003 0.459961C14.8547 0.459961 14.7105 0.488689 14.576 0.5445C14.4415 0.60031 14.3194 0.682107 14.2165 0.785205L0.931665 14.0701C0.828925 14.1731 0.747493 14.2953 0.692025 14.4298C0.636557 14.5643 0.60814 14.7084 0.6084 14.8539V30.3529C0.6084 30.6465 0.725038 30.9281 0.932654 31.1357C1.14027 31.3433 1.42186 31.46 1.71547 31.46H10.5721C10.8657 31.46 11.1473 31.3433 11.3549 31.1357C11.5625 30.9281 11.6791 30.6465 11.6791 30.3529Z"/>
    </CarIconStyle>
  );
};

export default Home;
