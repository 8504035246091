import styled from 'styled-components'

/*
  Headers for desktop and mobile
   - logo
   - status
   - deliveryETA
*/

export const MapContainerDesktop = styled.div`
  @media only screen and (min-width: 801px) {
    width: 100%;
    height: 500px;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const OrderStatusMobileMap = styled.div`
  @media only screen and (min-width: 801px) {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 260px;
    margin-top: 12px;
    overflow: scroll;
  }
`

export const OrderStatusMobileText = styled.p`
  @media only screen and (min-width: 801px) {
    display: none;
  }
  color: ${props => props.color};
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 32px;
`

// Large screen

export const DesktopDeliveryInfo = styled.div`
  @media only screen and (min-width: 801px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 100px;
  }
`
export const DesktopDeliveryAddressWrapper = styled.div`
  @media only screen and (min-width: 801px) {
    width: 50%;
  }
`

export const OrderStatusETABox = styled.div`
  @media only screen and (min-width: 801px) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: ${props => props.color};
    border-radius: 10px;
    padding: 8px 8px 2px 2px;
  }
`

export const DesktopETAStatus = styled.p`
  @media only screen and (max-width: 768px) {
    display: none;
  }
  align-self: center;
  color: white;
  margin: 0;
  font-size: 14px;
`

export const OrderStatusETABoxContentWrapper = styled.div`
  @media only screen and (min-width: 801px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const OrderStatusDesktopIconWrapper = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const OrderStatusETABoxIconWrapper = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const OrderStatusETABoxText = styled.div`
  @media only screen and (min-width: 801px) {
    margin-left: 15px;
    font-size: 18px;
    color: ${props => props.color};
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

// small screens
export const OrderStatusMobileIconWrapper = styled.div`
  @media only screen and (min-width: 801px) {
    display: flexbox;
  }
`

export const OrderStatusETAContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 20px;
  background-color: ${({ color }) => color};

  @media (max-width: 576px) {
    font-size: 16px;
  }
`;

export const OrderStatusMobileETA = styled.div`
  background: ${props => props.color};
  border-radius: 10px;
  height: 32px;
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  margin-top: 20px;
`
export const OrderStatusMobileETAText = styled.p`
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  height: 100%;
`
export const OrderStatusMobileETASpan = styled.span`
  font-weight: 700;
  line-height: 24px;
  color: #ffffff;
`

export const OrderStatusIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
`

export const OrderStatusIconText = styled.p`
  font-size: 10px;
  margin: 0px;
  color: ${props => props.color};
`

export const DeliveryAddressWrapper = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
`
export const Text = styled.div`
  margin-left: 15px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  /* FIRST/Black Text */

  color: #111827;
`
export const P = styled.p`
  margin-bottom: 10px;
  margin-left: 38px;
  font-size: 14px;
  line-height: 17px;
  color: #111827;
  font-weight: 300;
  margin-top: 5px;
`
export const Span = styled.div``
export const AddressTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const AddressIconWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
  align-self: center;
  @media only screen and (min-width: 650px) {
    flex: 1;
    display: flex;
    align-self: center;
    margin-left: 20px;
    justify-content: start;
  }
`
export const IconBox = styled.div`
  height: 25px;
  width: 25px;
  background: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
`
export const AddressHeaderWrapper = styled.div`
  display: flex;
`

export const OrderStatusSpanText = styled.p`
  color: ${props => props.color};
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  width: 60px;
`
