import { CarIconStyle } from "styles/IconStyles";

const Phone = () => {
  return (
    <CarIconStyle
      width="15"
      height="15"
      viewBox="0 0 16 16"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      className="justify-center"
    >
      <path d="M15.7158 13.1439L13.61 15.2329C13.2991 15.5517 12.8871 15.6597 12.4858 15.6602C10.7112 15.607 9.03386 14.7353 7.65664 13.8402C5.396 12.1956 3.32178 10.1564 2.01996 7.69205C1.52066 6.65867 0.934811 5.34016 0.990784 4.18671C0.995776 3.75282 1.1127 3.32697 1.41829 3.04729L3.52413 0.942505C3.96141 0.570554 4.3843 0.699156 4.66413 1.13241L6.35829 4.34498C6.5366 4.72557 6.43431 5.13335 6.16827 5.4053L5.39243 6.18075C5.34454 6.24635 5.31404 6.32103 5.31327 6.40231C5.61078 7.55393 6.51207 8.61536 7.30827 9.34583C8.10446 10.0763 8.96029 11.066 10.0712 11.3003C10.2085 11.3386 10.3767 11.352 10.4749 11.2607L11.3775 10.3428C11.6886 10.107 12.1378 9.99175 12.47 10.1846H12.4858L15.5416 11.9887C15.9902 12.2699 16.037 12.8133 15.7158 13.1439Z" />
    </CarIconStyle>
  );
};

export default Phone;
