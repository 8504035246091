const CarFront = ({ width, height, color }: any) => {
  return (
    <svg
      width={width || 24}
      height={height || 21}
      viewBox="0 0 24 21"
      fill={color || '#1F5EA9'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M20.6695 1.74761C20.4195 1.01011 19.7195 0.485107 18.8945 0.485107H5.14453C4.31953 0.485107 3.63203 1.01011 3.36953 1.74761L0.769531 9.23511V19.2351C0.769531 19.9226 1.33203 20.4851 2.01953 20.4851H3.26953C3.95703 20.4851 4.51953 19.9226 4.51953 19.2351V17.9851H19.5195V19.2351C19.5195 19.9226 20.082 20.4851 20.7695 20.4851H22.0195C22.707 20.4851 23.2695 19.9226 23.2695 19.2351V9.23511L20.6695 1.74761ZM6.39453 14.2351C5.35703 14.2351 4.51953 13.3976 4.51953 12.3601C4.51953 11.3226 5.35703 10.4851 6.39453 10.4851C7.43203 10.4851 8.26953 11.3226 8.26953 12.3601C8.26953 13.3976 7.43203 14.2351 6.39453 14.2351ZM17.6445 14.2351C16.607 14.2351 15.7695 13.3976 15.7695 12.3601C15.7695 11.3226 16.607 10.4851 17.6445 10.4851C18.682 10.4851 19.5195 11.3226 19.5195 12.3601C19.5195 13.3976 18.682 14.2351 17.6445 14.2351ZM4.28203 6.73511L5.58203 2.98511H18.4445L19.7445 6.73511H4.28203Z"
        fill={color || '#1F5EA9'}
      />
    </svg>
  )
}

export default CarFront
