import axios from 'axios'
import { OrderDataInterface } from './types'

export const fetchOrder = async (orderId: string) =>
  axios
    .get(
      `${
        process.env.NEXT_PUBLIC_API_URL ||
        'https://api.firstdelivery.com'
      }/orders/${orderId}/status`
    )
    .then(response => response.data as OrderDataInterface)
    .catch(err => err)
