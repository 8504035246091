import React, { useState, useRef, useEffect } from 'react'

interface DraggableBottomPanelProps {
  children: React.ReactNode
  hasFooter: boolean
}

// Constants for minimum height of panel
const MIN_VH = 35
const DRAG_ACTIVATION_DELAY = 100 // milliseconds

const DraggableBottomPanel: React.FC<DraggableBottomPanelProps> = ({
  children,
  hasFooter,
}) => {
  const MAX_VH = hasFooter ? -15 : 0 // dynamically set maximum vertical height (vh) of the panel

  // Refs and state to manage panel's position and drag gesture
  // Use refs for a performance boost over useState setters for most of these
  const panelRef = useRef<HTMLDivElement>(null) // Reference to the panel DOM element
  const scrollableRef = useRef<HTMLDivElement>(null) // Reference to the scrollable content
  const [currentVh, setCurrentVh] = useState<number>(MIN_VH) // State to track the current vertical height of the panel
  const startYRef = useRef<number>(0) // Starting Y position of the drag gesture
  const lastYRef = useRef<number>(0) // Last recorded Y position during drag
  const startTimeRef = useRef<number>(0) // Timestamp when the drag gesture starts
  const isDraggingRef = useRef<boolean>(false) // Boolean to track if dragging is occurring
  const currentVhRef = useRef<number>(currentVh) // Reference to track the current vh without causing re-renders
  const dragActivationTimeoutRef = useRef<NodeJS.Timeout | null>(null) // Timeout for drag activation delay

  const [isExpanded, setIsExpanded] = useState<boolean>(false) // State to track if panel is expanded
  const [isInitialTouchAtTop, setIsInitialTouchAtTop] =
    useState<boolean>(false) // State to track if initial touch was at top of scroll

  // Initializes drag gesture parameters
  const startGesture = (startY: number) => {
    isDraggingRef.current = true
    startYRef.current = startY
    lastYRef.current = startY // Initialize lastYRef with the start position
    startTimeRef.current = Date.now() // Capture the start time of the touch

    // Clear any existing timeout
    if (dragActivationTimeoutRef.current) {
      clearTimeout(dragActivationTimeoutRef.current)
    }

    // Set isInitialTouchAtTop after a short delay
    dragActivationTimeoutRef.current = setTimeout(() => {
      if (scrollableRef.current) {
        setIsInitialTouchAtTop(scrollableRef.current.scrollTop === 0)
      }
    }, DRAG_ACTIVATION_DELAY)
  }

  // Updates the panel's position based on the drag movement
  const moveGesture = (moveY: number) => {
    if (!isDraggingRef.current) return
    const deltaY = lastYRef.current - moveY // Calculate movement delta

    // Only allow dragging down if the initial touch was at the top of the scroll
    if (deltaY < 0 && isExpanded && !isInitialTouchAtTop) {
      return
    }

    // Adjust the panel's vertical height based on the delta, constraining within MAX_VH and MIN_VH
    let newVh =
      currentVhRef.current - deltaY * (100 / window.innerHeight)
    newVh = Math.max(MAX_VH, Math.min(newVh, MIN_VH))
    currentVhRef.current = newVh // Update for continuous drag updates
    setCurrentVh(newVh) // Update state to re-render with new position
    lastYRef.current = moveY // Update lastYRef for the next move calculation
    setIsExpanded(newVh < MIN_VH - 5) // Update expanded state
  }

  // Finalizes the drag gesture, possibly snapping to max/min positions based on velocity
  const endGesture = (endY: number) => {
    const elapsedTime = Date.now() - startTimeRef.current // Calculate gesture duration
    const distance = startYRef.current - endY // Total movement distance
    const velocity = distance / elapsedTime // Calculate velocity of the drag

    // If velocity exceeds a threshold, snap to MAX_VH or MIN_VH based on drag direction
    if (Math.abs(velocity) > 0.5) {
      const newVh = velocity > 0 ? MAX_VH : MIN_VH
      setCurrentVh(newVh)
      currentVhRef.current = newVh
      setIsExpanded(newVh < MIN_VH - 5)
    }

    isDraggingRef.current = false // Reset dragging flag
    setIsInitialTouchAtTop(false) // Reset the state

    // Clear the timeout if it hasn't fired yet
    if (dragActivationTimeoutRef.current) {
      clearTimeout(dragActivationTimeoutRef.current)
    }
  }

  // Handles start of drag gesture
  const handleStart = (clientY: number) => {
    startGesture(clientY)
  }

  // Handles movement during drag gesture
  const handleMove = (clientY: number) => {
    moveGesture(clientY)
  }

  // Handles end of drag gesture
  const handleEnd = (clientY: number) => {
    endGesture(clientY)
  }

  // Add global mouse event listeners
  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) =>
      isDraggingRef.current && handleMove(e.clientY)
    const handleMouseUp = (e: MouseEvent) => {
      if (isDraggingRef.current) {
        handleEnd(e.clientY)
      }
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
      if (dragActivationTimeoutRef.current) {
        clearTimeout(dragActivationTimeoutRef.current)
      }
    }
  }, [])

  return (
    <div
      ref={panelRef}
      className="fixed inset-x-0 bottom-0 z-10 transition-transform"
      style={{ transform: `translateY(${currentVh}vh)` }}
      onMouseDown={e => handleStart(e.clientY)}
      onTouchStart={e => handleStart(e.touches[0].clientY)}
      onTouchMove={e => handleMove(e.touches[0].clientY)}
      onTouchEnd={e => handleEnd(e.changedTouches[0].clientY)}
    >
      <div className="border-t rounded-tl-xl rounded-tr-xl bg-white shadow-lg overflow-hidden">
        <div className="p-4 cursor-pointer">
          <div className="flex justify-center">
            <div className="border-[3px] border-gray-300 w-3/12 rounded" />
          </div>
        </div>
        <div
          ref={scrollableRef}
          className={`
            px-4 pb-10 flex-grow
            ${hasFooter ? 'h-[70vh]' : 'h-[80vh]'}
            ${isExpanded ? 'overflow-y-auto' : ''}
          `}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default DraggableBottomPanel
