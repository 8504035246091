import React, { useEffect, useState } from 'react'
import ReactMapboxGl, {
  Feature,
  Layer,
  Marker,
} from 'react-mapbox-gl'

import { featureCollection, lineString, point } from '@turf/helpers'

const MAPBOX_TOKEN =
  'pk.eyJ1IjoiaGFiaXRhdG1pa2UiLCJhIjoiY2lsdHVpMWQxMDBjdnVza3NyNnV6Y3N3NCJ9.dWupCOy48vAo8BocejIdtg'

const MapView = ReactMapboxGl({
  accessToken: MAPBOX_TOKEN,
  refreshExpiredTiles: false,
})

//Map Icons
import MarkerIconMerchant from './Icons/MerchantIcon'
import CarMarker from './Icons/CarIconMarker'
import HomeIconMarker from './Icons/HomeIconMarker'

import bbox from '@turf/bbox'

const MapBox = ({ data }: any) => {
  const merchantLatitude = data?.merchant.location.coordinates[1]
  const merchantLongitude = data?.merchant.location.coordinates[0]
  const runnerLatitude = data?.runner?.location?.coordinates[1]
  const runnerLongitude = data?.runner?.location?.coordinates[0]
  const customerLatitude =
    data?.customer?.location?.coordinates &&
    data?.customer?.location?.coordinates[1]
  const customerLongitude =
    data?.customer?.location?.coordinates &&
    data?.customer?.location?.coordinates[0]

  const mapObj: any = {
    origin: {
      latitude: merchantLatitude,
      longitude: merchantLongitude,
    },
    destination: {
      latitude: customerLatitude,
      longitude: customerLongitude,
    },
    directions: [],
  }

  if (runnerLatitude && runnerLongitude) {
    mapObj.runner = {
      latitude: runnerLatitude,
      longitude: runnerLongitude,
    }
  }

  const finalBbox = buildBoundingBox(data.fulfillment?.status, mapObj)

  const renderMapOverlays = () => {
    return (
      <>
        {!['completed', 'cancelled'].includes(
          data?.fulfillment?.status
        ) &&
          runnerLatitude &&
          runnerLongitude && (
            <Marker coordinates={[runnerLongitude, runnerLatitude]}>
              <CarMarker width="28" height="28" status="black" />
            </Marker>
          )}
        <Marker
          coordinates={[merchantLongitude, merchantLatitude]}
          anchor="center"
        >
          <MarkerIconMerchant width="55" height="55" />
        </Marker>
        <Marker
          coordinates={[customerLongitude, customerLatitude]}
          anchor="center"
        >
          <HomeIconMarker width="55" height="55" />
        </Marker>
      </>
    )
  }

  return data.order?.dropoff_photo &&
    data.fulfillment?.status === 'completed' ? (
    <img src={data.order?.dropoff_photo} alt="Delivery Completed" />
  ) : (
    <MapView
      fitBounds={
        //Mapbox will blow up if you pass a fitBounds that isn't exactly
        //what it expects, so silent fail if not perfect bbox
        finalBbox.length !== 4
          ? undefined
          : [
              [finalBbox[0], finalBbox[1]],
              [finalBbox[2], finalBbox[3]],
            ]
      }
      style={'mapbox://styles/mapbox/streets-v11?optimize=true'}
      containerStyle={{
        width: '100%',
        height: '100%',
      }}
      fitBoundsOptions={{
        padding: 75,
      }}
      onStyleLoad={map => {
        if (map) {
          // Workaround for canvar size issue: https://github.com/alex3165/react-mapbox-gl/issues/372
          map.resize()
        }
      }}
    >
      {renderMapOverlays()}
    </MapView>
  )
}

function buildBoundingBox(
  status: string,
  directions: {
    runner: { latitude: number; longitude: number }
    directions: { latitude: number; longitude: number }[]
    origin: { latitude: number; longitude: number }
    destination: { latitude: number; longitude: number }
  }
): number[] {
  let bboxPoints = []

  try {
    if (directions.origin.longitude) {
      bboxPoints.push(
        point([
          directions.origin.longitude,
          directions.origin.latitude,
        ])
      )
    }

    if (directions.destination.longitude) {
      bboxPoints.push(
        point([
          directions.destination.longitude,
          directions.destination.latitude,
        ])
      )
    }

    if (status === 'in_progress' && directions.runner) {
      bboxPoints.push(
        point([
          directions.runner.longitude,
          directions.runner.latitude,
        ])
      )
    }

    const featureSet = featureCollection(bboxPoints)

    return bbox(featureSet)
  } catch (error) {
    return []
  }
}
export default MapBox
