interface OrderDetailsProps {
  tip: number
  subtotal: number
  items: any[]
}

const usdFormatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency',
})

const OrderDetails: React.FC<OrderDetailsProps> = ({
  tip,
  subtotal,
  items,
}) => {
  return (
    <div className="flex items-center justify-between border-b border-gray-300">
      {/* Items Count*/}
      <div className="flex-1">
        <span className="font-semibold text-center">Items</span>
        <p>{items?.length}</p>
      </div>

      {/* Subtotal */}
      <div className="flex-1">
        <span className="font-semibold text-center">Subtotal</span>
        <p>{usdFormatter.format(subtotal) || '$0.00'}</p>
      </div>

      {/* Vertical Separator */}
      <div className="w-px mx-4 mb-2 bg-gray-300 h-14"></div>

      {/* Tip */}
      <div className="flex-1">
        <span className="font-semibold text-center">Tip</span>
        <p>{usdFormatter.format(tip)}</p>
      </div>
    </div>
  )
}

export default OrderDetails
