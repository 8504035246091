import { CarIconStyle } from "styles/IconStyles";

const CarIcon = ({status, width, height, color}:any) => {
  return (
    <CarIconStyle
      width={width ? width : "44"}
      height={height? height :  "31"}
      viewBox={`0 0 40 29`}
      xmlns="http://www.w3.org/2000/svg"
      fill={color ? color : "#123233"}
    >
    <path d="M34.4625 9.97498H33.4653L26.6849 1.50787C26.3111 1.04123 25.837 0.664525 25.2978 0.405632C24.7585 0.146739 24.1679 0.0122818 23.5696 0.012207H10.2365C8.60531 0.012207 7.13867 1.00413 6.53282 2.51722L3.54656 10.1157C1.82873 10.5603 0.554688 12.1051 0.554688 13.9601V20.934C0.554688 21.4845 1.00097 21.9303 1.55198 21.9303H4.54385C4.54385 25.2317 7.22282 27.908 10.5276 27.908C13.8324 27.908 16.5113 25.2317 16.5113 21.9303H24.4896C24.4896 25.2317 27.1686 27.908 30.4734 27.908C33.7782 27.908 36.4571 25.2317 36.4571 21.9303H39.449C40 21.9303 40.4463 21.4845 40.4463 20.934V15.9526C40.4463 12.6512 37.7673 9.97498 34.4625 9.97498ZM10.5276 24.9191C8.8777 24.9191 7.53572 23.5785 7.53572 21.9303C7.53572 20.2821 8.8777 18.9415 10.5276 18.9415C12.1775 18.9415 13.5195 20.2821 13.5195 21.9303C13.5195 23.5785 12.1775 24.9191 10.5276 24.9191ZM15.0154 9.97498H7.84301L10.2365 3.99732H15.0154V9.97498ZM18.0073 9.97498V3.99732H23.5696L28.3566 9.97498H18.0073ZM30.4734 24.9191C28.8235 24.9191 27.4815 23.5785 27.4815 21.9303C27.4815 20.2821 28.8235 18.9415 30.4734 18.9415C32.1233 18.9415 33.4653 20.2821 33.4653 21.9303C33.4653 23.5785 32.1233 24.9191 30.4734 24.9191Z"/>     
    </CarIconStyle>
  );
};

export default CarIcon;