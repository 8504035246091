import { CarIconStyle } from "styles/IconStyles";

const Building = ({color, width, height}:any) => {
  return (
    <CarIconStyle
      width={width ? width : "31"}
       height={height ? height : "31"}
      xmlns="http://www.w3.org/2000/svg"
      fill={color ? color : "#1F5EA9"}
      viewBox="0 0 26 26"
    >
      <path d="M21.75 0.919922H4.25C2.1825 0.919922 0.5 2.60242 0.5 4.66992V8.20867C0.5 9.52492 0.9775 10.7212 1.75 11.6412V23.4199C1.75 23.7514 1.8817 24.0694 2.11612 24.3038C2.35054 24.5382 2.66848 24.6699 3 24.6699H13C13.3315 24.6699 13.6495 24.5382 13.8839 24.3038C14.1183 24.0694 14.25 23.7514 14.25 23.4199V17.1699H19.25V23.4199C19.25 23.7514 19.3817 24.0694 19.6161 24.3038C19.8505 24.5382 20.1685 24.6699 20.5 24.6699H23C23.3315 24.6699 23.6495 24.5382 23.8839 24.3038C24.1183 24.0694 24.25 23.7514 24.25 23.4199V11.6399C25.0225 10.7212 25.5 9.52492 25.5 8.20742V4.66992C25.5 2.60242 23.8175 0.919922 21.75 0.919922ZM23 4.66992V8.20867C23 9.63367 21.9387 10.8487 20.6362 10.9174L20.5 10.9199C19.1213 10.9199 18 9.79867 18 8.41992V3.41992H21.75C22.44 3.41992 23 3.98117 23 4.66992ZM10.5 8.41992V3.41992H15.5V8.41992C15.5 9.79867 14.3787 10.9199 13 10.9199C11.6213 10.9199 10.5 9.79867 10.5 8.41992ZM3 4.66992C3 3.98117 3.56 3.41992 4.25 3.41992H8V8.41992C8 9.79867 6.87875 10.9199 5.5 10.9199L5.36375 10.9162C4.06125 10.8487 3 9.63367 3 8.20867V4.66992ZM10.5 18.4199H5.5V14.6699H10.5V18.4199Z"/>
    </CarIconStyle>
  );
};

export default Building;
