import moment from 'moment'
import { OrderDataInterface } from './types'

export const formatEstimatedTime = (data: OrderDataInterface) => {
  const { runner_est_pickup_at, runner_estimated_dropoff } =
    data?.fulfillment!
  if (runner_estimated_dropoff) {
    return moment(runner_estimated_dropoff).format('h:mm a')
  } else if (runner_est_pickup_at) {
    return moment(runner_est_pickup_at).format('h:mm a')
  } else {
    return false
  }
}

export const formatEstimatedRange = (data: OrderDataInterface) => {
  const { runner_estimated_dropoff } = data?.fulfillment || {}

  if (!runner_estimated_dropoff) {
    return false // If no estimated dropoff time is available, return false
  }

  const now = moment() // Current time
  const dropoffTime = moment(runner_estimated_dropoff)
  let minutesUntilDropoff = dropoffTime.diff(now, 'minutes')

  // If the time until dropoff is negative (i.e., in the past), set a minimum window starting from now
  if (minutesUntilDropoff < 0) {
    minutesUntilDropoff = 0 // Start counting from now
  }

  // Define the end of the window as 5 minutes after the minutes until dropoff
  const windowEnd = minutesUntilDropoff + 5

  // Return the time window in the format "X - Y minutes"
  return `${minutesUntilDropoff} - ${windowEnd} minutes`
}
