import { CarIconStyle } from "styles/IconStyles";

const CarMarker = ({status, width, height, color}:any) => {
  return (
    <CarIconStyle
      width={width ? width : "44"}
      height={height? height :  "31"}
      viewBox={`0 0 44 31`}
      xmlns="http://www.w3.org/2000/svg"
      fill={color ? color : "#123233"}
    >
    <circle cx="20.6572" cy="20.4397" r="20"/>
    <path d="M29.3072 11.7022C29.0572 10.9647 28.3572 10.4397 27.5322 10.4397H13.7822C12.9572 10.4397 12.2697 10.9647 12.0072 11.7022L9.40723 19.1897V29.1897C9.40723 29.8772 9.96973 30.4397 10.6572 30.4397H11.9072C12.5947 30.4397 13.1572 29.8772 13.1572 29.1897V27.9397H28.1572V29.1897C28.1572 29.8772 28.7197 30.4397 29.4072 30.4397H30.6572C31.3447 30.4397 31.9072 29.8772 31.9072 29.1897V19.1897L29.3072 11.7022ZM15.0322 24.1897C13.9947 24.1897 13.1572 23.3522 13.1572 22.3147C13.1572 21.2772 13.9947 20.4397 15.0322 20.4397C16.0697 20.4397 16.9072 21.2772 16.9072 22.3147C16.9072 23.3522 16.0697 24.1897 15.0322 24.1897ZM26.2822 24.1897C25.2447 24.1897 24.4072 23.3522 24.4072 22.3147C24.4072 21.2772 25.2447 20.4397 26.2822 20.4397C27.3197 20.4397 28.1572 21.2772 28.1572 22.3147C28.1572 23.3522 27.3197 24.1897 26.2822 24.1897ZM12.9197 16.6897L14.2197 12.9397H27.0822L28.3822 16.6897H12.9197Z" fill="white"/>
    </CarIconStyle>
  );
};

export default CarMarker;
