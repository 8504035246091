/* eslint-disable @next/next/no-img-element */
import axios from 'axios'
import moment from 'moment'
import { useRef } from 'react'
import { useQuery } from '@tanstack/react-query'
import {
  AddressIconWrapper,
  AddressTitleWrapper,
  DeliveryAddressWrapper,
  MapContainerDesktop,
  IconBox,
  OrderStatusMobileETASpan,
  OrderStatusMobileETAText,
  OrderStatusETAContainer,
  OrderStatusMobileMap,
  P,
} from 'styles/orderStatus'
import Spinner from './Spinner'
import Building from './Icons/Building'
import CarIcon from './Icons/CarIcon'
import Home from './Icons/Home'
import Phone from './Icons/Phone'
import MapBox from './MapBox'
import { useRouter } from 'next/router'
import classNames from './classNames'
import FooterImage from './FooterImage'
import StatusIcon from './StatusIcon'
import { orderStatusWording } from './constants'
import { OrderDataInterface } from './types'
import {
  useAtlasTracking,
  useOrderData,
  useOrderStatus,
} from './effects'
import { formatEstimatedTime } from './format'

const OrderStatus = () => {
  const router = useRouter()
  const { orderId } = router.query as { orderId: string }

  const {
    data,
    order,
    customer,
    isLoading,
    isError,
    timelineEvents,
    primaryColor,
    container,
    delivery_service,
    fulfillment,
    merchant,
    runner,
    orderStatus,
    iconColor,
    fallbackColor,
  } = useOrderData(orderId)

  const usdFormatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  })

  if (isLoading) {
    return (
      <div ref={container}>
        <div className="flex flex-col items-center justify-center h-screen">
          <h3 className="mb-5 text-2xl font-normal text-gray-700 text-opacity-80 ">
            Loading order...
          </h3>

          <div>
            <Spinner />
          </div>
        </div>
      </div>
    )
  }

  return !order ? null : (
    <div className="w-full max-w-lg">
      <div className="flex w-full">
        <div className="flex-row w-full ">
          <div className="flex-col content-center w-full ">
            {delivery_service?.icon && (
              <div className="w-full max-w-screen-xl mx-auto">
                <div className="relative pb-[25%] max-h-[100px]">
                  <img
                    src={delivery_service?.icon}
                    alt="delivery service logo"
                    className="absolute top-0 left-0 w-full h-full object-contain"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-row items-center flex-grow mt-5">
            {/* STATUS */}
            <div
              className={classNames(
                `flex flex-col flex-grow justify-center w-1/2 font-semibold text-[20px] max-xs:text-[16px]`
              )}
              color={
                fulfillment?.status === 'cancelled'
                  ? 'red'
                  : `${'#191919'}`
              }
            >
              {orderStatusWording[orderStatus]}
            </div>

            {/* ETA */}
            {data && (
              <OrderStatusETAContainer
                color={primaryColor || fallbackColor}
              >
                <OrderStatusMobileETAText color={`${'#FFFFFF'}`}>
                  ETA{' '}
                  <OrderStatusMobileETASpan>
                    {formatEstimatedTime(data)}
                  </OrderStatusMobileETASpan>
                </OrderStatusMobileETAText>
              </OrderStatusETAContainer>
            )}
          </div>
        </div>
      </div>
      <OrderStatusMobileMap>
        {data && <MapBox data={data} />}
      </OrderStatusMobileMap>
      <StatusIcon
        fulfillment={fulfillment}
        iconColor={iconColor}
        primaryColor={primaryColor}
      />
      {merchant?.name ? (
        <DeliveryAddressWrapper>
          <AddressTitleWrapper>
            <Building color={`#191919`} width={32} height={25} />
            <span className="ml-1.5 text-lg font-bold">
              {merchant?.name}
            </span>
            <AddressIconWrapper>
              {merchant!.phone && (
                <IconBox color={`${primaryColor}`}>
                  <a href={`tel:${merchant?.phone}`}>
                    <Phone />
                  </a>
                </IconBox>
              )}
            </AddressIconWrapper>
          </AddressTitleWrapper>
          <div className="flex flex-row justify-between w-[90%] pl-10 pr-10 mt-1">
            <div className="text-sm font-medium leading-5 text-gray-900">
              Subtotal:
            </div>
            <div className="text-sm font-semibold">
              {!order?.payment?.subtotal &&
              order?.payment?.subtotal !== 0
                ? 'N/A'
                : usdFormatter.format(order?.payment?.subtotal)}
            </div>
          </div>
          <div className="flex flex-row justify-between w-[90%] pl-10 pr-10 mt-1">
            <div className="text-sm font-medium leading-5 text-gray-900">
              Tip:
            </div>
            <div className="text-sm font-semibold">
              {!order?.payment?.tip && order.payment?.tip !== 0
                ? 'N/A'
                : usdFormatter.format(order?.payment?.tip)}
            </div>
          </div>
        </DeliveryAddressWrapper>
      ) : null}
      <DeliveryAddressWrapper>
        <AddressTitleWrapper>
          <div className="ml-[-1px]">
            <CarIcon
              color={`${primaryColor}`}
              width={32}
              height={32}
            />
          </div>
          <span className="ml-1.5 text-xl font-semibold capitalize">
            {runner?.name}
          </span>
          <AddressIconWrapper>
            {runner?.phone && (
              <IconBox color={`${primaryColor}`}>
                <a href={`tel:${runner?.phone}`}>
                  <Phone />
                </a>
              </IconBox>
            )}
          </AddressIconWrapper>
        </AddressTitleWrapper>
        <P>{}</P>
      </DeliveryAddressWrapper>
      <AddressTitleWrapper>
        <div className="flex">
          <div className="w-[32px] ml-[3px]">
            <Home color={'black'} width={'23px'} height={'23px'} />
          </div>
          <span className="ml-0.5 text-xl font-semibold leading-6">
            {customer?.name}
          </span>
        </div>
      </AddressTitleWrapper>
      <P>{customer?.address}</P>
      {order?.order_number && (
        <div className="ml-10 font-medium leading-5 max-xs:text-md xs:text-xl">
          {'Order #' + ' ' + order?.order_number}
        </div>
      )}
      <div className="mb-2" />
      <MapContainerDesktop>
        {data && <MapBox data={data} />}
      </MapContainerDesktop>
      <div className="mb-2" />
      {delivery_service?.footer_image && (
        <FooterImage
          image={delivery_service.footer_image}
          link={delivery_service.footer_link}
        />
      )}
    </div>
  )
}

export default OrderStatus
