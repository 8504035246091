import React from 'react'

// Assuming the types for these components are already defined
import Building from '../Icons/Building'
import Cancel from '../Icons/Cancel'
import CarIcon from '../Icons/CarIcon'
import Home from '../Icons/Home'
import Mall from '../Icons/Mall'
import {
  OrderStatusMobileIconWrapper,
  OrderStatusIcons,
} from 'styles/orderStatus'

interface BarProps {
  iconCheck: number
}

const ICON_DATA: BarProps[] = [
  {
    iconCheck: 0,
  },
  {
    iconCheck: 1,
  },
  {
    iconCheck: 2,
  },
  {
    iconCheck: 3,
  },
]

interface OrderStatusIconWrapperProps {
  color: string
}

export const OrderStatusIconWrapper: React.FC<
  OrderStatusIconWrapperProps
> = ({ color }) => {
  return (
    <div
      className="h-2.5 rounded-sm relative flex flex-col w-1/4 justify-end items-center mt-2 first:ml-0 last:mr-0 mx-1.5"
      style={{ backgroundColor: color }}
    />
  )
}

interface RenderIconProps extends BarProps {
  fulfillment?: { status: string }
  iconColor: number
  primaryColor: string
}

const RenderIcon: React.FC<RenderIconProps> = ({
  iconCheck,
  fulfillment,
  iconColor,
  primaryColor,
}) => {
  const isCancelled = fulfillment?.status === 'cancelled'
  let iconColorValue = '#D3D3D3'

  if (isCancelled && iconColor === iconCheck) {
    iconColorValue = 'red'
  } else if (iconColor >= iconCheck) {
    iconColorValue = primaryColor
  }

  return <OrderStatusIconWrapper color={iconColorValue} />
}

const StatusBars: React.FC<{
  fulfillment?: { status: string }
  iconColor: number
  primaryColor: string
}> = ({ fulfillment, iconColor, primaryColor }) => {
  return (
    <OrderStatusMobileIconWrapper>
      <div className="flex justify-between items-center mb-2.5 w-full">
        {ICON_DATA.map((icon, i) => (
          <RenderIcon
            key={icon.iconCheck}
            {...icon}
            fulfillment={fulfillment}
            iconColor={iconColor}
            primaryColor={primaryColor}
          />
        ))}
      </div>
    </OrderStatusMobileIconWrapper>
  )
}

export default StatusBars
